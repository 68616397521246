import { DigistoreUpsellFunction } from "../public-interfaces";
import { documentReady } from "./browser/documentReady";
import { ds24_debug_function } from "./common/ds24_debug";
import { ds24_parse_settings } from "./common/ds24_parse_settings";
import { ds24_upsell_loader } from "./common/ds24_upsell";

/**
 * Enables Digistore24 upells functionality.
 * Adds upsell session id and secure buy buttons.
 * Default link_replace_tries is 5.
 * @example digistoreUpsell('root_url=https://www.digistore24.com link_replace_tries=3)
 */
export const digistoreUpsell: DigistoreUpsellFunction = function( settings_string?: string ): void
{
    // eslint-disable-next-line prefer-rest-params
    ds24_debug_function('digistoreUpsell', arguments);
    const settings_array = ds24_parse_settings( settings_string ?? '' );

    for (const key in settings_array)
    {
        const val = settings_array[ key ];

        switch (key)
        {
            case 'root_url':
                window.DIGISTORE_ROOT_URL = val.replace(/\/$/, "");
                break;
            case 'link_replace_tries':
                window.DS24_LINK_REPLACE_TRIES = val;
                break;
        }
    }

    documentReady(ds24_upsell_loader);
};
